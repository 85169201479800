.loading_icon,
.loading_logo {
	position: absolute !important;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 1;
	display: flex;
}

.loading_logo {
	animation: loading_Logo_Pulse 5s ease-in-out 0s infinite;
	width: 3.75rem;
	height: auto;
}

.loading_icon_outer {
	animation: loading_Spin 1.5s linear 0s infinite, loading_Pulse 5s ease-in-out 0s infinite;
	font-size: 15rem;
	font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 0, 'opsz' 48;
}

@keyframes loading_Logo_Pulse {
	0%,
	100% {
		transform: translate(-50%, -50%) scale(2);
	}
	50% {
		transform: translate(-50%, -50%) scale(3);
	}
}

@keyframes loading_Spin {
	from {
		transform: translate(-50%, -50%) rotateZ(0deg);
	}
	to {
		transform: translate(-50%, -50%) rotateZ(360deg);
	}
}

@keyframes loading_Pulse {
	0%,
	100% {
		opacity: 1;
		text-shadow: 0px 0px 10px var(--pc-mid);
	}
	50% {
		opacity: 0;
		text-shadow: 0px 0px 0px var(--pc-mid);
	}
}
