.bottom-nav-link * {
	transition: 0.15s ease-out;
	color: var(--bs-white) !important;
}

.bottom-nav-link .material-icon {
	font-variation-settings: 'FILL' 0, 'wght' 500, 'GRAD' 0, 'opsz' 48;
	transform: translate(0px, 0px);
	text-shadow: 0px 0px 0px var(--pc-light);
}

.bottom-nav-link.active .material-icon {
	font-variation-settings: 'FILL' 1, 'wght' 900, 'GRAD' 0, 'opsz' 48;
	transform: translate(0px, -1px);
	text-shadow: -1px 1px 0px var(--pc-primary);
}

.bottom-nav-link .text-nav-label {
	display: inline-block;
	font-size: 0.75rem;
	transform: translate(0px, 0px);
	text-shadow: 0px 0px 0px var(--pc-light);
}

.bottom-nav-link.active .text-nav-label {
	font-size: 0.8rem;
	transform: translate(0px, -1px);
	text-shadow: -1px 1px 0px var(--pc-primary);
}
