@font-face {
	font-family: magneto-custom;
	src: url(./MAGNETOB.TTF) format('truetype');
}

@font-face {
	font-family: RubikMonoOne;
	src: url(./RubikMonoOne-Regular.ttf) format('truetype');
}

/* Roboto */
@font-face {
	font-family: Roboto;
	src: url(./Roboto/Roboto-Thin.ttf) format('truetype');
	font-style: normal;
	font-weight: 100;
}
@font-face {
	font-family: Roboto;
	src: url(./Roboto/Roboto-ThinItalic.ttf) format('truetype');
	font-style: italic;
	font-weight: 100;
}
@font-face {
	font-family: Roboto;
	src: url(./Roboto/Roboto-Light.ttf) format('truetype');
	font-style: normal;
	font-weight: 300;
}
@font-face {
	font-family: Roboto;
	src: url(./Roboto/Roboto-LightItalic.ttf) format('truetype');
	font-style: italic;
	font-weight: 300;
}
@font-face {
	font-family: Roboto;
	src: url(./Roboto/Roboto-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 400;
}
@font-face {
	font-family: Roboto;
	src: url(./Roboto/Roboto-Italic.ttf) format('truetype');
	font-style: italic;
	font-weight: 400;
}
@font-face {
	font-family: Roboto;
	src: url(./Roboto/Roboto-Medium.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: Roboto;
	src: url(./Roboto/Roboto-MediumItalic.ttf) format('truetype');
	font-style: italic;
	font-weight: 500;
}
@font-face {
	font-family: Roboto;
	src: url(./Roboto/Roboto-Bold.ttf) format('truetype');
	font-style: normal;
	font-weight: 700;
}
@font-face {
	font-family: Roboto;
	src: url(./Roboto/Roboto-BoldItalic.ttf) format('truetype');
	font-style: italic;
	font-weight: 700;
}
@font-face {
	font-family: Roboto;
	src: url(./Roboto/Roboto-Black.ttf) format('truetype');
	font-style: normal;
	font-weight: 900;
}
@font-face {
	font-family: Roboto;
	src: url(./Roboto/Roboto-BlackItalic.ttf) format('truetype');
	font-style: italic;
	font-weight: 900;
}

/* Material Symbols Icons */
@import 'material-symbols/index.scss';

.material-icon {
	vertical-align: inherit;
	font-size: inherit;
}

.material-symbols-outlined,
.material-symbols-rounded,
.material-symbols-sharp {
	font-variation-settings: 'FILL' 0, 'wght' 900, 'GRAD' 0, 'opsz' 48;
}
.material-symbols-rounded.filled {
	font-variation-settings: 'FILL' 1, 'wght' 900, 'GRAD' 0, 'opsz' 48;
}

/* Font Awesome Icons */
@import 'font-awesome/css/font-awesome.css';
