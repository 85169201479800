$enable-negative-margins: true;

/* Set theme SCSS variables */
$primary: #4c3276;

$secondary: #bdade1;

$tertiary: #fecdf4;
$tertiary-rgb: 254, 205, 244;

$accent: #f99fd0;
$accent-rgb: 249, 159, 208;

$light: #d3d1d7;

$mid: #585d5f;
$mid-rgb: 88, 93, 95;

$dark: #150a25;

/* Create theme CSS variables */
:root {
	--#{$app-prefix}-primary: #{$primary};

	--#{$app-prefix}-secondary: #{$secondary};

	--#{$app-prefix}-tertiary: #{$tertiary};
	--#{$app-prefix}-tertiary-rgb: #{$tertiary-rgb};

	--#{$app-prefix}-accent: #{$accent};
	--#{$app-prefix}-accent-rgb: #{$accent-rgb};

	--#{$app-prefix}-light: #{$light};

	--#{$app-prefix}-mid: #{$mid};
	--#{$app-prefix}-mid-rgb: #{$mid-rgb};

	--#{$app-prefix}-dark: #{$dark};
}

/* Additional theme classes to match bootstrap's format */
.bg-tertiary {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--pc-tertiary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-accent {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--pc-accent-rgb), var(--bs-bg-opacity)) !important;
}

.bg-mid {
	--bs-bg-opacity: 1;
	background-color: rgba(var(--pc-mid-rgb), var(--bs-bg-opacity)) !important;
}

.border-tertiary {
	--bs-border-color: var(--pc-tertiary) !important;
}

.border-accent {
	--bs-border-color: var(--pc-accent) !important;
}

.border-mid {
	--bs-border-color: var(--pc-mid) !important;
}

.text-tertiary {
	color: $tertiary !important;
}

.text-accent {
	color: $accent !important;
}

.text-mid {
	color: $mid !important;
}
