body * {
	font-family: Roboto, sans-serif;
	font-style: normal;
	font-weight: 900;
}

.logo-icon {
	transition: 0.2s ease-out;
	box-shadow: 0px 0px 0px var(--pc-mid);
}

.logo-icon .material-icon {
	font-variation-settings: 'FILL' 1, 'wght' 900, 'GRAD' 0, 'opsz' 48;
}

/* .logo-icon:hover {
	box-shadow: -2px 5px 3px var(--pc-mid), -3px 5px 5px var(--pc-mid);
	transform: translate(2px, -5px);
} */

.brand_body_font {
	font-family: Roboto;
	font-size: 1rem;
	line-height: 1;
}

.brand_font {
	font-family: magneto-custom;
	font-size: 1rem;
	line-height: 1;
	/* text-shadow: -0.0625em 0.0625em 0 var(--pc-mid); */
}

.brand_text_shadow {
	text-shadow: -0.0625em 0.0625em 0 var(--pc-mid);
}

.text_pop {
	color: white;
	text-shadow: -1px -1px 5px var(--bs-primary), 1px -1px 3px var(--bs-secondary), -1px 1px 3px var(--bs-primary), 1px 1px 3px var(--bs-dark);
}

.brand_font_xl {
	font-size: 3rem;
}

.brand_font_lg {
	font-size: 2rem;
}

.brand_font_sm {
	font-size: 0.5rem;
}
