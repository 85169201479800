.Friend-List-Item {
	--timing-function: cubic-bezier(1, -0.35, 0, 1.31);
	position: absolute;
	transition-property: all;
	transition-duration: 0.75s;
	transition-timing-function: var(--timing-function);

	animation-name: fade-in;
	animation-duration: 0.75s;
	animation-fill-mode: backwards;
	animation-timing-function: var(--timing-function);
	/* animation-delay: calc(var(--animation-offset) * 0.1s); */
	z-index: calc(1000 + var(--animation-offset));
}

@keyframes fade-in {
	0% {
		top: 0;
	}

	100% {
	}
}
